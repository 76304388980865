<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { router } from '@inertiajs/vue3';
import { Link } from '@inertiajs/vue3';
import TextInput from '@/Components/TextInput.vue';
import InputError from '@/Components/InputError.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import { QuestionMarkCircleIcon, MagnifyingGlassIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/24/outline';
import translate from '@/Composables/baseVue';

const props = defineProps({
    forNavbar: {
        type: Boolean,
        default: false,
    },
});

let textSearch = ref("");
let error = ref("");
const inputID = props.forNavbar ? "forNavbar" : "inputSearch";

onMounted(() => {
    setTimeout(() => { // without it, the dom does not have time to charge before mount
        let input = document.getElementById(inputID);
        if (input) { // additional precaution (if time out is not enough)
            input.addEventListener('keydown', event => {
                if (event.key === "Enter") {
                    search();
                }
            });
        }
    }, 1);
});

onUnmounted(() => {
    document.removeEventListener('keydown', event => {
        if (event.key === "Enter") {
            search();
        }
    });
});

const search = () => {
    error.value = "";
    textSearch.value = textSearch.value.trim();
    if (textSearch.value == "") {
        error.value = translate("Please complete the field.");
    } else if (textSearch.value.length < 2) {
        error.value = translate("The field must be at least :min characters long.", { min: 2 });
    } else if (RegExp("^N[MR]_", "gi").exec(textSearch.value) && textSearch.value.length <= 6) {
        error.value = translate("The transcript identifier (:transcript) must contain at least 4 digits.", { transcript: "NM" });
    } else if (RegExp("^ENST", "gi").exec(textSearch.value) && textSearch.value.length <= 7) {
        error.value = translate("The transcript identifier (:transcript) must contain at least 4 digits.", { transcript: "ENST" });
    } else {
        router.visit("/search/get_results/" + encodeURIComponent(encodeURIComponent(textSearch.value)));
    }
};
</script>

<template>
    <div>
        <div v-if="!forNavbar" class="grid md:grid-cols-12 gap-x-4 gap-y-2 items-center mb-6">
            <div class="md:col-start-2 md:col-span-8 col-span-6">
                <TextInput :id="inputID" v-model="textSearch" type="text" class="block w-full justify-center"
                    :placeholder="__('Gene Symbol | Transcript | HGVS Nomenclature | Genomic Position | Identifier')" />
            </div>
            <div class="md:col-span-2 col-span-5">
                <PrimaryButton class="block w-full justify-center" @click="search">
                    {{ __('Search') }}
                </PrimaryButton>
            </div>
            <div class="col-span-1">
                <QuestionMarkCircleIcon class="h-6 w-6 text-gray-600 stroke-2" v-tooltip.bottom-end="{
                    html: true,
                    content: '<div class=max-w-md>' + __('Examples:') + '<br>BRCA2, ENST00000380152, NM_000059' + '<br>BRCA2:c.147A>C, BRCA2:p.Glu49Asp'
                        + '<br>ENST00000380152:c.147A>C, ENST00000380152:p.Glu49Asp' + '<br>NM_000059:c.147A>C, NM_000059:p.Glu49Asp'
                        + '<br>13:32319156, 13:32319156 A, 13:32319156 A/C' + '<br>CANDV1234, CANDC5678, CANDP9012' + '<br>rs10454253 </div>',
                    theme: 'my-tooltip'
                }" />
            </div>
            <div class="md:col-start-2 md:col-span-8 order-last">
                <InputError v-if="error" :message="error" />
            </div>
            <div class="md:col-span-2 md:order-last col-span-5">
                <Link :href="route('search.advanced')">
                <SecondaryButton class="block w-full justify-center">
                    {{ __('Advanced Search') }}
                </SecondaryButton>
                </Link>
            </div>
        </div>

        <div v-else>
            <TextInput :id="inputID" v-model="textSearch" type="search" class="xl:w-64 pl-10" :placeholder="__('Search...')" @change="textSearch == '' ? error = '' : ''" />
            <button type="submit" class="absolute left-0 top-0 mt-3 ml-3" @click="search">
                <MagnifyingGlassIcon class="h-5 w-5 stroke-2" />
            </button>
            <Link :href="route('search.advanced')" class="pl-1">
            <AdjustmentsHorizontalIcon class="h-6 w-6 text-gray-600 inline-block" v-tooltip.bottom-start="{
                content: __('Advanced Search'),
                theme: 'my-tooltip'
            }" />
            </Link>
            <InputError :message="error" size="xs" />
        </div>
    </div>
</template>
